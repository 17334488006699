import { useState, useEffect } from 'react'
import { request } from '../lib/axisClient'
import { EventReport } from '../types/event-report'

export const useEventReports = () => {
	const [eventReports, setEventReports] = useState<EventReport[]>([])
	const [totalPage, setTotalPage] = useState(0)
	
	const limit = 10;

	const fetchEventReports = async (page = 1) => {
		const offset = page === 1 ? 0 : (page - 1) * limit
		try {
			const res = await request({
				url: `/event_reports?limit=${limit}&offset=${offset}`,
				method: 'GET'
			})
			setEventReports(res.contents)
			setTotalPage(Math.ceil(res.totalCount / limit))
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		fetchEventReports()
	}, [])

	return { eventReports, fetchEventReports, totalPage }
}
