import React from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Story as StoryType } from '../types/story'
import { EventReport as EventReportType } from '../types/event-report'

export const ArticleCard = (params: { article: StoryType | EventReportType }) => {
	const [t, i18n] = useTranslation()

	const { article } = params

	return (
		<div className="article-card">
			<img className={`${!article.vol && 'event-report'}`} src={article.coverImage.url} alt={article.title} />
			<div className="md:mt-4">
				{article.vol && <div className="font-lato vol">Vol.{article.vol}</div>}
				<div className={`title ${i18n.language === 'en' && article.titleEn ? 'font-lato' : 'font-yu'}`}>
					{i18n.language === 'en' && article.titleEn || article.title}
				</div>
				{article.date && <div className="font-lato vol">{format(new Date(article.date), 'yyyy.MM.dd')}</div>}
			</div>
		</div>
	)
}
