import React, { useEffect } from "react";
import { Link } from "gatsby";
import * as queryString from "query-string";
import { ScrollAndShow } from "../../components/scroll-and-show";
import { Layout } from "../../components/layout";
import { ArticleCard } from "../../components/article-card";
import { useEventReports } from "../../hooks/useEventReports";
import { SEO } from "../../components/seo";

const EventReportsPage = ({ location }) => {
	const { eventReports, fetchEventReports, totalPage } = useEventReports();

	const { page } = queryString.parse(location.search);

	useEffect(() => {
		fetchEventReports(Number(page));
	}, [page]);

	return (
		<Layout>
			<SEO
				title={`EVENT | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description=""
				keywords={[
					"メキシコ料理",
					"渋谷",
					"ファインメキシカン",
					"ルビア",
					"sarasa",
				]}
			/>
			<h1 className="h1">EVENT | RUBIA ルビア / DJ sarasa</h1>
			<div className="article">
				<div className="links font-lato">
					<Link to="/stories">
						<div className="stories">STORIES</div>
					</Link>
					<Link to="/event_reports">
						<div className="event-report selected">EVENT</div>
					</Link>
				</div>
				<div className="container event-report">
					{eventReports.map((eventReport, index) => (
						<Link to={`/event_reports/${eventReport.title}`}>
							<ArticleCard article={eventReport} />
						</Link>
					))}
				</div>

				<ScrollAndShow>
					<div className="pagination-buttons font-lato">
						{totalPage > 0 && Number(page) && Number(page) !== 1 ? (
							<Link to={`/event_reports?page=${Number(page) - 1}`}>← PREV</Link>
						) : (
							<a className="disabled">← PREV</a>
						)}
						{totalPage > 0 && Number(page) !== totalPage ? (
							<Link
								to={`/event_reports?page=${
									Number(page) ? Number(page) + 1 : 2
								}`}
							>
								NEXT →
							</Link>
						) : (
							<a className="disabled">NEXT →</a>
						)}
					</div>
				</ScrollAndShow>

				<ScrollAndShow>
					<div className="categories-link">
						<Link className="font-yu" to="/categories">
							カテゴリー一覧へ
						</Link>
					</div>
				</ScrollAndShow>
			</div>
		</Layout>
	);
};

export default EventReportsPage;
